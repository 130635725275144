import React from 'react'
import Header from '../components/global/header/header'
import Hero from '../components/heros/standard'
import CallToAction from '../components/global/cta'
import Footer from '../components/global/footer/footer'
import PageTransition from 'gatsby-plugin-page-transitions'
import { FormattedMessage } from 'react-intl'

// Images
import ctaImage from '../images/7Z8A0083_Output.jpg'
import heroImage from '../images/our-office.jpg'
import { StickyContainer, Sticky } from 'react-sticky';
import cx from 'classnames';
import { withIntl } from '../i18n'


const timelineEntries = [{
    date: '2020',
    entries:[{
        text: '2020_applications_para'
    }]
},{
    date: '2017',
    entries:[{
        text: 'diversification_into_more_industries_para'
    }]
},{
    date: '2015',
    entries:[{
        text: 'version_3_of__para'
    }]
},{
    date: '2013',
    entries:[{
        text: 'version_2_deployed_para'
    }]
},{
    date: '2011',
    entries:[{
        text: 'version_2_of__para'
    }]
},{
    date: '2010',
    entries:[{
        text: 'flagship_application_deployed_para'
    }]
},{
    date: '2009',
    entries:[{
        text: 'version_1_of_flagship_software_para'
    }]
},{
    date: '2008',
    entries:[{
        text: 'company_was_incorporated'
    }]
},{
    date: '2006',
    entries:[{
        text: 'mission_and_vision_para'
    }]
}];




class AboutPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            contactOpen: false,
        };
    }

    toggleContact = () => {
        this.setState({
            contactOpen: !this.state.contactOpen
        });

        document.body.classList.toggle('noscroll');
    }

    isSmallerThan = (pix) => {
        if (typeof document !== 'undefined') {
            return document.documentElement.clientWidth < pix;
        }
        return false;
    }


    componentDidMount() {
        if(document.body.classList.contains('noscroll')) {
            document.body.classList.remove('noscroll');
        }
    }


    scrollingArea() {
        return (
            <StickyContainer>
                <section className="timeline-section pad">
                    <div className="row expanded collapse">
                        {/* Title */}
                        <div className="small-12 large-push-2 large-4 xlarge-3 huge-2 columns print-timeline__heading">
                            <div className="row expanded">
                                <div className="small-12 columns">
                                    {!this.isSmallerThan(1200) ? (
                                        <Sticky topOffset={0} bottomOffset={50}>
                                        {({ style, isSticky }) => (
                                            <div className={cx("timeline-title mpad", { [ "sticky" ]: isSticky})} style={style}>
                                                <em><FormattedMessage id="company"/></em>
                                                <h2 className="dark"><FormattedMessage id="history"/></h2>
                                            </div>
                                        )}
                                        </Sticky>
                                    ) : (
                                        <div className="timeline-title mpad">
                                            <em><FormattedMessage id="company"/></em>
                                            <h2 className="dark"><FormattedMessage id="history"/></h2>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>

                        {/* Features List */}
                        <div className="small-12 large-pull-1 large-6 columns">
                            <div className="row expanded">
                                <div className="small-12 columns">
                                    <ul className="timeline">
                                    { timelineEntries.map(entry => {
                                            return ( 
                                                <li key={entry.date}>
                                                    <span className="disc"><em className="light noline">{entry.date}</em></span>
                                                    { 
                                                        entry.entries.map(text => <span key={text.text} className="entry"><FormattedMessage id={text.text}/></span>)
                                                    }
                                                    { entry.date !== "2006" && <div className="stalk"></div> }
                                                </li> 
                                            )
                                        }) 
                                    }
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </StickyContainer>
        );
    }

    render() {
        return (
            <PageTransition
                defaultStyle={{
                    transition: 'all 1100ms cubic-bezier(0.8, 0, 0.2, 1)',
                    position: 'relative',
                    width: '100%',
                    opacity: 0,
                    maxWidth: '2000px'
                }}

                transitionStyles={{
                    entering: {  opacity: 1 },
                    entered: { opacity: 1 },
                    exiting: { opacity: 0, }
                }}

                transitionTime={1250}
            >
                <Header
                    headerStyle="std"
                    contactOpen={this.state.contactOpen}
                    action={() => this.toggleContact()}
                    {...this.props }
                />
            
                <Hero
                    classManipulators="about"
                    pageTitleSub="all"
                    pageTitleMain="about_us"
                    pageIntro="with_a_technical_reputation_para"
                    featImage={heroImage}
                />
            
                { this.scrollingArea() }

            
                <CallToAction
                    image={ctaImage}
                    heading="tailor_made_perfect_fit"
                    content="lets_get_in_touch"
                    buttonLabel="contact_us"
                    action={() => this.toggleContact()}
                />
            
                <Footer />
            </PageTransition>
        )
    }
}

export default withIntl(AboutPage)




// ./src/pages/about.jsx